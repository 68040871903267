var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"brand my-8 my-md-16"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-card-title',{staticClass:"brand__title text-uppercase white--text mx-auto text-center",class:_vm.site == 'creators'
            ? 'brand__title--creators mb-4 mb-md-8'
            : 'brand__title--standard mb-8 mb-md-16'},[_vm._v(_vm._s(_vm.title))])],1),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.columns),function(col){return _c('v-col',{key:col.index,attrs:{"col":"4"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-dialog',{key:col.index,attrs:{"transition":"fade-transition","max-width":"800","content-class":"elevation-0"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"brand__image mx-auto d-none d-xl-flex",attrs:{"contain":"","max-width":"400","src":col.image,"lazy-src":col.imageLazy},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on)),_c('v-img',{staticClass:"brand__image mx-auto d-xl-none",attrs:{"contain":"","max-width":"300","src":col.image,"lazy-src":col.imageLazy},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})]}}],null,true),model:{value:(_vm.dialog[col.index]),callback:function ($$v) {_vm.$set(_vm.dialog, col.index, $$v)},expression:"dialog[col.index]"}},[_c('v-card',{attrs:{"color":"transparent"}},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","fab":"","text":""},on:{"click":function($event){return _vm.$set(_vm.dialog, col.index, false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-img',{staticClass:"mx-auto",attrs:{"max-width":"800","src":col.image,"lazy-src":col.imageLazy},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-card-title',{staticClass:"brand__subtitle text-uppercase mx-auto text-no-wrap my-2",class:_vm.site == 'creators'
                ? 'brand__subtitle--creators'
                : 'brand__subtitle--standard'},[_vm._v(" "+_vm._s(col.title)+" ")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('p',{staticClass:"brand__description mx-4 mx-sm-8 text-center",class:_vm.site == 'creators'
                  ? 'brand__description--creators'
                  : 'brand__description--standard'},[_vm._v(" "+_vm._s(col.description)+" ")])])],1)],1)}),1),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},_vm._l((_vm.buttons),function(button){return _c('Button',{key:button.index,attrs:{"name":button.name,"to":button.to,"page":"brand"}})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }